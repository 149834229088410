/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import Layout from '../components/layout';
import { SelectCategories } from '../components/CookieConsent';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import * as analytics from '../utils/analytics';

export default ({ pageContext }) => {
  analytics.usePageCategory('not_set');
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Layout
      title={translate('cookieConsent.categoryTitle')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
    >
      <SelectCategories hasAcceptButton={false} />
    </Layout>
  );
};
